.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: fixed;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  z-index: 9999;
  left: 0px;
  top: 0px;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}


.loader {
  display: flex;
  justify-content: center;
   align-items: center;
  min-width : 100vw;
  height :100vh;
  background : rgb(255, 255, 255);
}
.loader > div {
  width: 20px;
  height: 20px;
  background: #8385aa;
  opacity: 0.5;
  border-radius: 50%;
  margin : 3px;
  animation: loader 0.6s infinite alternate;
}
.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes loader {
  to {
    opacity: 0.1;
    transform: translate(0, -20px);
    background : pink;
  }
}