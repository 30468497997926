.centered {
  /* position: relative; */
  top: 50%;
  left: 50%;
  /* margin-top: -50px;
    margin-left: -100px; */
}

.paddingContent {
  margin: 30px;
}

.success-icon-badge {
  z-index: 99;
  position: relative;
  top: 36px;
  background-color: white;
}

.overlay {
  &:hover {
    background-color: grey !important;
    color: white !important;
  }
}
